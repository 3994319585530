.table-containerUsuarios{
    background-color: var(--blanco);
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.114);
      width: 55%;
    min-height: 35vh;
    max-height: 35vh;
    border-radius: 10px;
    padding: 1rem;
    overflow: auto;

}

.table-containerUsuarios::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    cursor: pointer;
    background-color: transparent;

}

.table-containerUsuarios::-webkit-scrollbar-thumb {
    background-color: var(--color1);
    border-radius: 10px;
  
}
@media (max-width: 824px){
    .table-containerUsuarios{
          width: 100%;
       
    
    }
}