.headerBack {
    display: none;
}

@media (max-width: 900px) {
    .headerBack{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        position: absolute;
        width: 100%;
        background-color: var(--color1);
        z-index: 200;
        top: 0;
       
     }
     .headerBack a{
        color: var(--blanco);
    }
    .headerBack span{
        color: var(--blanco);
        font-weight: 500
    }
}
