.ProductsContainPage{
    padding: 50px 7%;
    width: 100%;
    display: flex;
    gap: 2rem;
    min-height: 100vh;
    overflow: hidden;
    
}

.filtrosPage{
    max-width: 17rem;
    min-width: 17rem;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    background-color: var(--blanco);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073);
    height: 100%;
}

.ProductsGrap{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
.inputsGrap{
    display: flex;
    flex-direction: column;
    gap: 2rem;

}
.inputsGrap select{
    background-color: var(--gris);
    border-radius: 10rem;
    padding: 8px;
    border: 0.3px solid #00000037;
}
.range input{
    width: 100%;
}

@media (max-width: 1000px) {
    .range {
        width: 100%;
        padding: 0px 4% ;
    }
    .inputsGrap{
        
        display: flex; 
        flex-direction: row;
        gap: 10px;
        overflow: scroll;
        padding-left: 4%;
        width: 100%;
        
    }
    .inputsGrap::-webkit-scrollbar{
      background-color: transparent;
     }
     
     .inputsGrap::-webkit-scrollbar-thumb{
        background-color: transparent;
     }
    .inputsGrap select{
       width: 10rem;
       padding: 8px  2rem 8px 8px ;
    }
    .ProductsContainPage{
        padding: 20px 0%  100px;
      
       flex-direction: column;
       background-color: var(--blanco);
       margin-top: -2rem;
       border-radius: 30px 30px  0 0;
      
}
    .filtrosPage{
        max-width: 100%;;
        min-width: 100%;;
        background-color:transparent;
        padding: 0;
        border-radius: 10px;
        box-shadow: none;
        height: 100%;
        gap: 10px;
    }
    .searchInput{
        padding: 1rem;
    }
    .ProductsGrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 1rem;

    }
    .ProductsGrap .cardProdcutmasVendido{
        margin-bottom: 2rem;
    }
    
}