header {
    width: 100%;
    z-index: 10;
    transition: all 0.2s linear;
    position: absolute;
    z-index: 100;
}
nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    height: 9vh;
    height: 4rem;
    transition: all 0.2s linear;
   
    padding: 30px 10% 30px;
    background-color: var(--blanco);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.071);
}

.deFLexNavs{
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    gap: 5rem;
}


.nav_items.open {
    transform: translateX(0) !important;
  
}


.nav_toggle{
    display: flex !important;
    flex-direction: column;       
    cursor: pointer;
    justify-content: right;
    text-align: right;
   

}
.nav_toggle span{
    width: 25px;
    height: 3px;
    background: var(--color1);
    margin-bottom: 5px;
    border-radius: 20px;
    transform-origin: 3px 0px;
    transition: all 0.2s linear;
    display: flex;
    justify-content: right;
    text-align: right;
  
}
.nav_toggle span:nth-child(1) {
    width: 15px;
    margin-left: 25%;
}

.nav_toggle span:nth-child(2) {
    width: 25px; 
    margin-left: -2%;
}

.nav_toggle span:nth-child(3) {
    width: 35px; 
    margin-left: -30%;
}



.logo img{
    width: 50px;
    border-radius: 100%;
}


.fondo{
    display: flex;
    flex-direction: column;
    height: 15rem;

}
.fondo img{
    display: flex;
    flex-direction: column;
    height: 15rem;

}
.loadingBannerFondo{
    width: 100%;
    height: 15rem;
     background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
}

.enlaces{
    display: flex;
    flex-direction: column;
    text-align: left;
   padding: 10px;
   overflow-y: scroll;
  
 
}
.enlaces::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    cursor: pointer;
    background-color: transparent;
   
}
 
.enlaces::-webkit-scrollbar-thumb {
    background-color: var(--color1); 
    border-radius: 10px;
    margin-top: 3rem;
  }

.enlaces a{
    color: var(--text-color2)!important;
    text-decoration: none;
    display: flex;
    margin-top: 1rem;
    gap: 1rem;
    padding: 10px;
    border-radius: 7px;
    align-items: center;
   
}
.enlaces a:hover{
    background-color: var(--color1);
    color: var(--blanco)!important;
}



.enlaces2 a{
    padding: 0px 20px;
    width: 8rem;
    text-decoration: none;
    color: var(--text-color2)!important;
 }


.modalNav {
    top: 0;
    background-color: var(--blanco);
    width: 30%;
  
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 0px 10px 10px 0px;
    border: none;
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.333); 
    z-index: 999; 
}


.modalNav-content {
    width: 100%;
}

@media (max-width: 900px) {
    .modalNav {
        
        width: 80%;
       
    }
    .deFLexNavs{
        display: flex;
        gap: 2rem;
    }
    
 .deNone{
    display: none;
 }
  
    
    
    nav{
   
        padding: 0px 3%;
        border-radius: 0 0 0 30px;
        background-color: var(--color1)!important;
        box-shadow: none;
    }
 
  
.enlaces2 a{
    display: none;
  }

 
  .logo img{
    width: 35px;

}

.nav_toggle span{
    background: var(--blanco);
  
}
.fondo{
    height: 10rem;

}
.fondo img{
    height: 10rem;

}
.loadingBannerFondo{
    height: 10rem;
}
}


