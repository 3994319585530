.infoUser{
    background-color: var(--blanco);
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.114);
    border-radius: 10px;
    padding: 1rem;
    width: 40vh;
    min-height: 40vh;
    max-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.iconName{
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 20px;
    text-align: center;
    background-color: var(--color1);
    color: var(--blanco);
    font-size: 30px;
}

.name{
    font-size: 20px;
    font-weight: 400;
    color: var(--text-color2);
}
.email{
    font-size: 17px;
    color: var(--text-color2);
}
.rol{
    color: green;
}
@media (max-width: 1024px){
    .infoUser{
       
        width: 100%;
       
    }
}