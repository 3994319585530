.btnWhatsapp img{
    width: 30px;
}

.btnWhatsapp{
    position: fixed;
    top: 75%;
    right: 0;
    left: 92%;
    background-color: rgb(30, 184, 30);
    color: var(--blanco);
    padding: 25px;
    border-radius: 100%;
   width: 2.5rem;
   height:2.5rem;
   display: flex;
   justify-content: center;
   align-items: center;
   border: none;
   font-size: 17px;
   z-index: 2;
   cursor: pointer;
   box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.196);
}


.overlay-wpp{
    
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.384); 
        z-index: 999; 
        display: flex;
        justify-content:end;
        padding: 2rem;
        padding-top: 10rem;


}
.modal-wpp{
    top: 37%;
    bottom: 0;
    background-color: var(--blanco);
    width: 20rem;
    height: 23rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius:15px ;
    margin-right: 2%;
    position: fixed;
    
}
.containModalWpp{
    
   

}
.headerWpp{
    background-color: rgb(30, 184, 30);
    width: 100%;
    color: var(--blanco);
    border: none;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    
}
.headerWpp button{
    font-weight: bold;
    background-color: transparent;
    border: none;
    color: var(--blanco);
}
.btnsWpp{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 10px;
    overflow-y: scroll;
    height: 11rem;
    padding-top: 1rem;
}
.btnsWpp::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    cursor: pointer;
    background-color: transparent;
   
}
 
.btnsWpp::-webkit-scrollbar-thumb {
    background-color: rgb(30, 184, 30);
    border-radius: 10px;
  
  }
  .sendWpp textarea{
    display: flex;
    background-color: var(--gris);
    border-radius: 15px;
    min-height: 3rem;
    resize: none; 
    color: var(--text-color2);
}
.sendWpp{
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 1vh;
}
.sendWpp button{
    color: rgb(30, 184, 30);
    border: none;
    background-color: transparent;
    font-size: 25px;
}
.mensaje{
 background-color: var(--gris);
 padding: 10px;
 margin: 15px 20px 0px 10px;
 border-radius:0px 20px 20px 20px ;

}
.mensaje p{
    font-weight: 400;
    color: var(--text-color2);
}
@media (max-width: 900px) {
    .btnWhatsapp{
        position: fixed;
        top: 80%;
        right: 0;
        left: 80%;
       
    }
}
@media (max-width: 500px) {
    
    .modal-wpp{
        top: 0;
        background-color: var(--blanco);
        width: 95%;
        height: 25rem;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-radius:15px;
        margin-top: 40vh;
        margin-right: 0;
    }
    
.overlay-wpp{
   
    justify-content:center;
    padding: 10px;

}
.btnsWpp{
    height: 12rem;

}
}